@import "../../../scss/styles.scss";

.vouchers__container {
  background: none;
  display: flex;
  flex-direction: column;
  // width: fit-content;
  width: 100%;
  height: 100%;
  background: $backgroundWhite;
  // gap: 1rem;

  .vouchers__body {
    display: flex;
    // padding-bottom: 3rem;
    height: 100%;
    .vouchers__propList__container {
      display: flex;
      flex-direction: column;
      // width: auto;
      width: 100%;
      height: 100%;
      justify-content: center;
      .vouchers__propList__filter {
        // @include fontRegular;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // color: $mainBlue;
        gap: 1rem;
        white-space: nowrap;
        padding: 1rem;

        // margin: 1rem 0;
        :nth-child(2) {
          // @include fontBold;
        }
        .ant-select {
          color: $mainBlue;
        }
      }
      .vouchers__propList__filter__count {
        background: none;
        font-size: 1.5rem;
        color: rgba($color: #000000, $alpha: 0.7);
      }
      .vouchers__propList__body {
        display: flex;
        padding: 2rem;
        gap: 2rem;
        place-content: space-evenly;

        .vouchers__propList__content {
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;
          place-content: center;
          margin-top: 1rem;
          // flex-direction: column;
          // gap: 2rem;
          row-gap: 2rem;
          justify-content: space-evenly;
          color: $mainBlue;
        }
      }
    }
    .vouchers__noRut__container {
      display: flex;
      flex-direction: column;
      min-height: 200px;
      width: 100%;
      align-items: center;
      place-content: center;
      gap: 1rem;
      svg {
        color: rgba($color: #000000, $alpha: 0.7);
        path {
          stroke: rgba($color: #000000, $alpha: 0.7);
        }
      }
      .vouchers__noRut__icon {
        font-size: 4rem;
      }
      .vouchers__noRut__text {
        color: rgba($color: #000000, $alpha: 0.7);
      }
      button {
        @include ppbutton;
      }
    }
    .vouchers__spin {
      display: grid;
      width: 100%;
      place-content: center;
    }
  }
}
