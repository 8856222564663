@import "../../../../scss/styles.scss";

.VouchersTable__container {
  @include ppcard;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
  overflow-x: auto;

  // margin: 0 2rem;
  .VouchersTable__title {
    font-size: 1.5rem;
  }
  .VouchersTable__btmText {
    font-size: 1rem;
    align-self: center;
    color: rgb(0 156 255);
  }
  .ant-table-cell:nth-child(6) {
    white-space: nowrap;
  }
  .ant-table-cell:nth-child(7) {
    white-space: nowrap;
  }
  .ant-table-cell:nth-child(8) {
    white-space: nowrap;
  }
}
