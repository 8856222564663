@import "../../../scss/styles.scss";
.InputRut__container {
  //   background: #ccc;
  display: flex;
  flex-direction: column;
  align-self: center;

  /* generic */
  --gutterSm: 0.4rem;
  --gutterMd: 0.8rem;
  --gutterLg: 1.6rem;
  --gutterXl: 2.4rem;
  --gutterXx: 7.2rem;
  --colorPrimary400: #6693c4;
  --colorPrimary600: #426284;
  --colorPrimary800: #324b66;
  --fontFamily: "Dosis", sans-serif;
  --fontSizeSm: 1.2rem;
  --fontSizeMd: 1.6rem;
  --fontSizeLg: 2.1rem;
  --fontSizeXl: 2.8rem;
  --fontSizeXx: 3.6rem;
  --lineHeightSm: 1.1;
  --lineHeightMd: 1.8;
  --transitionDuration: 300ms;
  --transitionTF: cubic-bezier(0.645, 0.045, 0.355, 1);

  /* floated labels */
  --inputPaddingV: var(--gutterMd);
  --inputPaddingH: var(--gutterLg);
  --inputFontSize: var(--fontSizeLg);
  --inputLineHeight: var(--lineHeightMd);
  --labelScaleFactor: 0.8;
  --labelDefaultPosY: 50%;
  --labelTransformedPosY: calc(
    (var(--labelDefaultPosY)) - (var(--inputPaddingV) * var(--labelScaleFactor)) -
      (var(--inputFontSize) * var(--inputLineHeight))
  );
  --inputTransitionDuration: var(--transitionDuration);
  --inputTransitionTF: var(--transitionTF);

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  .InputRut__title {
    // margin: 0 0 var(--gutterXx) 0;
    padding: 0;
    color: $mainBlue;
    font-size: var(--fontSizeMd);
    font-weight: 400;
    line-height: var(--lineHeightSm);
    text-align: left;
    white-space: nowrap;
    // text-shadow: -0.1rem 0.1rem 0.2rem var(--colorPrimary800);
  }

  .InputRut__Input {
    position: relative;
  }

  .InputRut__InputText {
    display: block;
    margin: 0;
    padding: var(--inputPaddingV) var(--inputPaddingH);
    color: inherit;
    width: 100%;
    font-family: inherit;
    font-size: var(--inputFontSize);
    font-weight: inherit;
    line-height: var(--inputLineHeight);
    border: none;
    border-radius: 0.4rem;
    transition: box-shadow var(--transitionDuration);
    border-bottom: 1px solid #ccc;
  }

  .InputRut__InputText::placeholder {
    color: #b0bec5;
  }

  .InputRut__InputText:focus {
    outline: none;
    // box-shadow: 0.1rem 0.5rem 1.3rem var(--colorPrimary600);
    box-shadow: 0.2rem 0.8rem 1.6rem var(--colorPrimary600);
  }

  .InputRut__InputLabel {
    display: block;
    position: absolute;
    bottom: 50%;
    left: 1rem;
    color: #ccc;
    font-family: inherit;
    font-size: var(--inputFontSize);
    font-weight: inherit;
    line-height: var(--inputLineHeight);
    opacity: 0;
    transform: translate3d(0, var(--labelDefaultPosY), 0) scale(1);
    transform-origin: 0 0;
    transition: opacity var(--inputTransitionDuration) var(--inputTransitionTF),
      transform var(--inputTransitionDuration) var(--inputTransitionTF),
      visibility 0ms var(--inputTransitionDuration) var(--inputTransitionTF),
      z-index 0ms var(--inputTransitionDuration) var(--inputTransitionTF);
  }

  .InputRut__InputText:placeholder-shown + .InputRut__InputLabel {
    visibility: hidden;
    z-index: -1;
  }

  .InputRut__InputText:not(:placeholder-shown) + .InputRut__InputLabel,
  .InputRut__InputText:focus:not(:placeholder-shown) + .InputRut__InputLabel {
    visibility: visible;
    z-index: 1;
    opacity: 1;
    transform: translate3d(0, var(--labelTransformedPosY), 0)
      scale(var(--labelScaleFactor));
    transition: transform var(--inputTransitionDuration), visibility 0ms,
      z-index 0ms;
  }
  button {
    @include ppbutton;
  }
}
// .InputRut__container {
//   //   background: #314c66;
//   display: grid;
//   place-content: center;
//   height: 100%;
//   margin: 2rem;
//   .InputRut__text {
//     margin: 1rem;
//     font-size: 4rem;
//     color: rgba($color: #3d5f80, $alpha: 0.5);
//     // color: rgb(4, 50, 124);
//     text-transform: uppercase;
//     width: 100%;
//     // -webkit-box-reflect: below -3px linear-gradient(transparent, rgba(11, 70, 138, 0.267));
//     -webkit-box-reflect: below -3px linear-gradient(transparent, rgba(#314c66, 0.267));
//     line-height: 54px;
//     white-space: nowrap;
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//     outline: none;
//     letter-spacing: 0.04rem;
//     // animation: animateRut 1s linear infinite;
//     animation: animateRut 1s linear none 3s;
//   }
//   .InputRut__text:hover {
//     animation: none;
//   }
// }

// @keyframes animateRut {
//   0%,
//   18%,
//   20%,
//   50.1%,
//   60%,
//   65.1%,
//   80%,
//   90.1%,
//   92% {
//     // color: rgb(4, 50, 124);
//     color: rgba($color: #3d5f80, $alpha: 0.5);

//     text-shadow: none;
//   }
//   18.1%,
//   20.1%,
//   30%,
//   50%,
//   60.1%,
//   65%,
//   80.1%,
//   90%,
//   92.1%,
//   100% {
//     color: #fff;
//     text-shadow: 0 0 10px rgb(6, 149, 231), 0 0 20px rgb(6, 149, 231),
//       0 0 40px rgb(6, 149, 231), 0 0 80px rgb(6, 149, 231),
//       0 0 160px rgb(6, 149, 231);
//   }
// }
