@import "../../../scss/styles.scss";

.Home__bottom {
  @include ppshadow;
  display: grid;
  height: 100%;
  place-content: center;
  background: $backgroundWhite;
  max-height: 4rem;
  z-index: 1;

  .Home__IT {
    font-size: 1rem;
    color: $mainBlue;
  }
}
