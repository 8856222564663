@import "../../../scss/styles.scss";

.Home__container {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  //   background: red;
  flex-direction: column;

  .Home__body {
    display: grid;
    height: 100%;
    place-content: center;
    background-color: $backgroundWhite;
  }
  .Home__layout {
    display: flex;
    gap: 2rem;
    // grid-template-columns: 2fr 1fr;
    height: 100%;
    // width: 100vw;
    justify-content: space-around;
    padding: 2rem;
  }
  .Home__imgContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  .Home__imgTitle {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
  }
  .Home__text1 {
    font-size: 2.1rem;
    font-weight: 300;
    margin: 0;
  }
  .Home__text2 {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0;
  }
  .Home__img {
    display: flex;
    flex-direction: column;
    // height: 100%;
    // width: 100%;
    // justify-content: center;
    img {
      height: 45vh;
    }
  }
  .Home__input {
    display: flex;
    margin: 3rem;
    text-align: center;
    text-align-last: center;
  }
  .Home__greetings {
    display: block;
    position: relative;
    font-size: 0.5rem;
    align-self: center;
    text-align: center;
    a {
      text-decoration: none;
      color: grey;
    }
  }
}
