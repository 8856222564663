$mainBlue: #324b66;
$backgroundWhite: #fff;
$backgroundGray: #ccc;
$backgroundSnowWhite: #f1f6f2;
$barelyBlue: #e0e0e0;
@mixin ppcard {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  background: $backgroundWhite;
  border-radius: 0.5rem;
}
@mixin ppbutton {
  background: #324b66;
  color: white;
  padding: 1rem 2rem;
  font-size: 1rem;
  text-transform: uppercase;
  border-radius: 0.5rem;
  margin-top: 5rem;
  cursor: pointer;
}
@mixin ppshadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
* {
  font-family: "Oswald";
}
