/* @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap"); */
.App {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background: #e0e0e0;
  justify-content: center;
  color: rgba(#000, 0.7);
}
* {
  font-family: "Oswald", sans-serif;
  letter-spacing: 0.04rem;
}
.App__inner {
  display: grid;
  /* justify-content: center; */
  min-width: 1200px;
  max-width: 1800px;
  overflow: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
