@import "../../../scss/styles.scss";

.HomeHeader__container {
  @include ppshadow;
  display: flex;
  background-color: $mainBlue;
  color: white;
  // height: 10%;
  line-height: 1;
  justify-content: space-between;
  padding: 1rem;
  z-index: 1;
  .HomeHeader__title {
    // padding: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
    font-size: 1.5rem;
    align-self: center;
  }
  .HomeHeader__logo {
    height: 60px;
    align-self: center;
  }
}
