@import "../../../../scss/styles.scss";

.VouchersCart__container {
  @include ppcard;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  min-width: 300px;
  gap: 2rem;
  transition: 100ms ease-in-out;
  min-height: 470px;

  .VouchersCart__header {
  }
  .VouchersCart__title {
    font-size: 1.2rem;
    color: rgba($color: #000000, $alpha: 0.7);
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
  .VouchersCart__body {
    // margin-bottom: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    min-height: 263px;

    .ant-table-cell:first-child {
      width: 76px;
    }
  }
  .VouchersCart__delete {
    font-size: 1.4rem;
    color: red;
  }
  .VouchersCart__totalContainer {
    display: flex;
    padding: 1rem 0 0 0;
    font-size: 1.3rem;
    justify-content: center;
    margin-top: auto;
    color: rgba($color: #000000, $alpha: 0.7);
  }
  .VouchersCart__btnContainer {
    display: flex;
    justify-content: center;
    transition: 1s ease-in-out;
    .VouchersCart__btnServipag {
      margin-bottom: 1rem;
      width: 150px;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
        rgba(0, 0, 0, 0.24) 0px 1px 2px;
      padding: 0.2rem;
      border-radius: 10px;
    }
    .btnServipag_d {
      display: none;
      cursor: none;
    }
  }
}
